// @ts-nocheck
/* eslint-enable */
// App
import React from 'react';

// Icon
import IconCheckmarkWhite from 'images/icons/checkmark-white.svg';

// Misc / utils
import * as S from './styles';

const Checkbox = ({ className, ariaLabel, children, flex = false, isChecked, onChange, ...rest }) => {
  return (
    <S.Label flex={flex} className={className}>
      <S.HiddenCheckbox type="checkbox" checked={isChecked} onChange={onChange} aria-label={ariaLabel} {...rest} />
      <S.StyledCheckbox checked={isChecked}>
        {isChecked && <img src={IconCheckmarkWhite} height="14px" width="14px" alt="" />}
      </S.StyledCheckbox>
      {children}
    </S.Label>
  );
};

export default Checkbox;
